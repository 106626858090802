import { Route, Routes, Navigate, useLocation} from 'react-router-dom'
import './App.css';

import 'react-notifications-component/dist/theme.css'
import Login from "./Login/Login";
import Room from "./Room/Room";
import Header from "./Header/Header";
import MyRoomAdministrator from "./MyRoom/Administrator/MyRoom/MyRoomAdministrator";
import CreateDatasetAdministrator from "./MyRoom/Administrator/CreateDataset/CreateDatasetAdministrator";
import Annotation from "./Annotation/Annotation";
import Notification from "./Notification/Notification";
import {useSelector} from "react-redux";
import SuccessLoginPage from "./SuccessLoginPage/SuccessLoginPage";
import TimeSeries from "./TimeSeries/TimeSeries";
import TextAnnotationComponent from "./TextAnnotationComponent/TextAnnotationComponent";
import {AUTH_METHOD} from "../utils/constants";
import Menu from "./Menu/Menu";
import UploadPageComponent from "./UploadPageComponent/UploadPageComponent";
import SmartReports from "./SmartReports/SmartReports";
import PipelineComponent from "./PiplineComponent/PipelineComponent";
import BatchProcessing from "./PiplineComponent/BatchProcessing/BatchProcessing";
import ProcessingStats from "./PiplineComponent/ProcessingStats/ProcessingStats";
import BinaryClassifier from "./PiplineComponent/BinaryClassifier/BinaryClassifier";
import DatasetsPage from './Datasets/DatasetsPage';


function App() {

  let location = useLocation();
  const {showNotification} = useSelector(store => store.app)
  const {userInfo} = useSelector(store=>store.user)
  return (
    <div className="app">


      {location.pathname === "/login"  || location.pathname === "/success_login" || (location.pathname === "/" && !userInfo?.name)  || (location.pathname === "/auth/local" && !userInfo?.name)  ? "" : <Header />}
      {location.pathname === "/login"  || location.pathname === "/success_login" || (location.pathname === "/" && !userInfo?.name)  || (location.pathname === "/auth/local" && !userInfo?.name)  ? "" : <Menu />}

      {showNotification && <Notification />}
      {/*<FileUploadProgress />*/}
        <Routes>
          <Route path='/datasets' element={<DatasetsPage />} />
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          {AUTH_METHOD === 'local' ? <Route path='/auth/local' element={<Login />} /> : ""}
          <Route path='/room' element={<Room />} />
          <Route path='/my_room_admin' element={<MyRoomAdministrator />} />
          <Route path='/create_dataset' element={<CreateDatasetAdministrator />} />
          <Route path='/success_login' element={<SuccessLoginPage />} />
          <Route path='/annotation' element={<Annotation />} />
          <Route path='/ts_annotate' element={<TimeSeries />} />
          <Route path='/text_annotate' element={<TextAnnotationComponent />} />
          <Route path='/upload_data' element={<UploadPageComponent />} />
          <Route path='/smart_reports' element={<SmartReports />} />
          <Route path='/pipeline' element={<PipelineComponent />} />
          <Route path='/bach_processing' element={<BatchProcessing />} />
          <Route path='/processing_stats' element={<ProcessingStats />} />
          <Route path='/binary_classifier' element={<BinaryClassifier />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    </div>
  );
}

export default App;
