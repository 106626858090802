const data = [
  {
    "id": 1,
    "title": "Типы транспортных средств",
    "nsud_id": "00024F.DA.000001206",
    "description": "Изображения, на которых присутствуют транспортные средства разных классов. Разметка описывает координаты прямоугольной области, внутри которой присутствует ТС заданного класса. Классы указаны в соответствии с классификатором EUR6.",
    "value": 1000
  },
  {
    "id": 2,
    "title": "Специальные транспортные средства",
    "nsud_id": "00024F.DA.000001208",
    "description": "Изображения, на которых присутствуют транспортные средства, соответствующие категории «спецтехника». Разметка описывает координаты прямоугольной области, внутри которой присутствует ТС заданной категории. \nДопустимы следующие категории: \n- скорая медицинская помощь и реанимация;\n- полиция, спецтехника МВД;\n- пожарные и спецтехника МЧС;\n- коммунальные службы и аварийные сервисы (водоканал, газовая служба и пр.).",
    "value": 1000
  },
  {
    "id": 3,
    "title": "Интенсивность дорожного потока",
    "nsud_id": "00024F.DA.000001209",
    "description": "Изображения участков дорог и перекрестков, на которых виден общий план, охватывающий несколько полос движения и допускающий более трех автомобилей в полосе. Разметка представляет собой координаты всех областей изображения, содержащего движущиеся ТС, а также указывает количество таких ТС.",
    "value": 1000
  },
  {
    "id": 4,
    "title": "Поиск пешеходов на изображениях",
    "nsud_id": "00024F.DA.000001210",
    "description": "Изображения участков дорог и перекрестков, на которых виден общий план, охватывающий несколько полос движения, обочину или пешеходный переход. Разметка представляет собой координаты всех областей изображения, содержащего  одного или нескольких пешеходов.",
    "value": 1000
  },
  {
    "id": 5,
    "title": "Пустые и занятые парковочные места",
    "nsud_id": "00024F.DA.000001211",
    "description": "Изображения участков дорог и примыкающих к ним площадей и парковочных карманов, на которых видны припаркованные ТС или свободные места для их размещения с масштабом охвата не менее трех ТС. Разметка представляет собой координаты прямоугольных областей, внутри которых присутствует ТС или свободное место, и каждый такой элемент содержит признак «занят» / «свободен».",
    "value": 1000
  },
  {
    "id": 6,
    "title": "Состояние дорожного покрытия и элементы дорожной инфраструктуры",
    "nsud_id": "00024F.DA.000001212",
    "description": "Изображения участков дорог и перекрестков, на которых видны общий план дороги и дорожная инфраструктура, позволяют оценить качество поверхности и состояние элементов инфраструктуры.\nИнфраструктура оценивается по выявленным элементам: качество обочины или бордюров, обрамляющих дорогу, наличие люков (открыты / закрыты) и наличие искусственных неровностей (целая / поврежденная).\nАннотация к изображениям содержит оценку, при наличии дефектов указывает область, содержащую найденный элемент или дефект.",
    "value": 1000
  },
  {
    "id": 7,
    "title": "Количество колёс легкового и грузового транспорта",
    "nsud_id": "00024F.DA.000001213",
    "description": "Изображения легковых и грузовых транспортных средств с координатами колес, полностью или частично видимых на изображении.",
    "value": 1000
  },
  {
    "id": 8,
    "title": "Государственные регистрационные знаки",
    "nsud_id": "00024F.DA.000001214",
    "description": "Изображения, охватывающие области координат, включающие государственные регистрационные знаки и их символьное содержимое.",
    "value": 1000
  },
  {
    "id": 9,
    "title": "Распознавание дорожных знаков",
    "nsud_id": "00024F.DA.000001215",
    "description": "Изображения участков дорог и перекрестков, на которых виден общий план и присутствуют дорожные знаки. Разметка представляет собой координаты всех областей изображения, содержащего дорожный знак.",
    "value": 1000
  },
  {
    "id": 10,
    "title": "Распознавание элементов дорожной разметки",
    "nsud_id": "00024F.DA.000001216",
    "description": "Изображения участков дорог и перекрестков, на которых виден общий план и присутствуют элементы дорожной разметки. Разметка представляет собой координаты всех областей изображения, содержащего дорожную разметку.",
    "value": 1000
  },
  {
    "id": 11,
    "title": "Распознавание зданий на основе БПЛА съемки",
    "nsud_id": "00024F.DA.000001300",
    "description": "Изображения, на которых присутствуют здания. Разметка описывает координаты области, внутри которой присутствует здание заданного класса. Классы указаны в соответствии с классификатором",
    "value": 1152
  },
  {
    "id": 12,
    "title": "Распознавание сооружений на основе данных с БПЛА",
    "nsud_id": "00024F.DA.000001301",
    "description": "Изображения, на которых присутствуют сооружения. Разметка описывает координаты области, внутри которой присутствует сооружение заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1027
  },
  {
    "id": 13,
    "title": "Распознавание границ земельных участков на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001302",
    "description": "Изображения, на которых присутствуют земельные участки. Разметка описывает координаты области, внутри которой присутствует земельный участок заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1236
  },
  {
    "id": 14,
    "title": "Распознавание столбов электропередач на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001303",
    "description": "Изображения, на которых присутствуют столбы электропередач. Границы столбов регистрируются по видимым контурам столбов в т.ч. по теням столбов. Разметка описывает координаты области, внутри которой присутствует столб заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1094
  },
  {
    "id": 15,
    "title": "Распознавание водных линейных объектов на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001304",
    "description": "Изображения, на которых присутствуют водные линейные объекты - реки и ручьи. Разметка описывает координаты области, внутри которой присутствует река или ручей заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1089
  },
  {
    "id": 16,
    "title": "Распознавание водных площадных объектов на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001305",
    "description": "Изображения, на которых присутствуют водные площадные объекты - распознавание озер, болот, прудов. Разметка описывает координаты области, внутри которой присутствует озеро, болото, пруд заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1074
  },
  {
    "id": 17,
    "title": "Распознавание границ сельхоз угодий на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001306",
    "description": "Изображения, на которых присутствуют сельхоз объекты - распознавание пашен, сельхоз полей. Разметка описывает координаты области, внутри которой присутствует пашня, сельхоз поле заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1035
  },
  {
    "id": 18,
    "title": "Распознавание древесно-кустарниковой растительности на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001307",
    "description": "Изображения, на которых присутствуют древесно-кустарниковоя растительность - распознавание контуров древесно-кустарниковой растительности за пределами населенных пунктов. Разметка описывает координаты области, внутри которой присутствует древесно-кустарниковая растительность заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1113
  },
  {
    "id": 19,
    "title": "Распознавание контуров огородов на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001308",
    "description": "Изображения, на которых присутствуют контуры огородов - распознавание огородов в пределах границ земельных участков ЛПХ. Разметка описывает координаты области, внутри которой присутствует контур огорода заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1083
  },
  {
    "id": 20,
    "title": "Распознование дорог с твердым покрытием на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001309",
    "description": "Изображения, на которых присутствуют контуры дорог с твердым покрытием. Разметка описывает координаты области, внутри которой присутствует контур дороги с твердым покрытием заданного класса. Классы указаны в соответствии",
    "value": 1206
  },
  {
    "id": 21,
    "title": "Распознование дорог без твердого покрытия на основе данных БПЛА",
    "nsud_id": "00024F.DA.000001310",
    "description": "Изображения, на которых присутствуют контуры дорог без твердого покрытия. Разметка описывает координаты области, внутри которой присутствует контур дороги без твердого покрытия заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1348
  },
  {
    "id": 22,
    "title": "Определение лиственных деревьев на основе БПЛА съемки",
    "nsud_id": "00024F.DA.000001311",
    "description": "Изображения, на которых присутствуют контуры лиственных деревьев. Разметка описывает координаты области, внутри которой присутствует контур лиственных деревьев заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 955
  },
  {
    "id": 23,
    "title": "Определение хвойных деревьев на основе БПЛА съемки",
    "nsud_id": "00024F.DA.000001312",
    "description": "Изображения, на которых присутствуют контуры хвойных деревьев. Разметка описывает координаты области, внутри которой присутствует контур хвойных деревьев заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 955
  },
  {
    "id": 24,
    "title": "Определение состояния деревьев на основе БПЛА съемки",
    "nsud_id": "00024F.DA.000001313",
    "description": "Изображения, на которых присутствуют контуры вероятно усыхающих, погибающих деревьев. Разметка описывает координаты области, внутри которой присутствует контур вероятно усыхающих, погибающих деревьев заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 955
  },
  {
    "id": 25,
    "title": "Определение нормализованного относительного вегетационного индекса (NDVI) на основе данных БПЛА съемки сельхоз угодий",
    "nsud_id": "00024F.DA.000001314",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 731
  },
  {
    "id": 26,
    "title": "Определение зеленого нормализованного относительного вегетационного индекса (GNDVI) на основе данных БПЛА съемки сельхоз угодий",
    "nsud_id": "00024F.DA.000001315",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 770
  },
  {
    "id": 27,
    "title": "Определение индекса растительности с коррекцией по почве (SAVI) на основе данных БПЛА съемки сельхоз угодий",
    "nsud_id": "00024F.DA.000001316",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 768
  },
  {
    "id": 28,
    "title": "Определение нормализованного разностного индекса красного края (NDRE) на основе данных БПЛА съемки сельхоз угодий",
    "nsud_id": "00024F.DA.000001317",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 769
  },
  {
    "id": 29,
    "title": "Определение модифицированного триангуляционного индекса растительности (MTVI2) на основе данных БПЛА съемки сельхоз угодий",
    "nsud_id": "00024F.DA.000001318",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 734
  },
  {
    "id": 30,
    "title": "Определение индекса хлорофилла – дальний ИК (CIRE)",
    "nsud_id": "00024F.DA.000001319",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 769
  },
  {
    "id": 31,
    "title": "Определение модифицированного индекса поглощения хлорофилла (MCARI)",
    "nsud_id": "00024F.DA.000001320",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 726
  },
  {
    "id": 32,
    "title": "Определение индекса земного хлорофилла (MTCI)",
    "nsud_id": "00024F.DA.000001321",
    "description": "Изображения, на которых присутствуют контуры сельхоз угодий для мониторинга состояния посевов. Разметка описывает координаты области, внутри которой присутствует контур сельхоз угодий заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 747
  },
  {
    "id": 33,
    "title": "Распознавание наружной рекламы (билборды)",
    "nsud_id": "00024F.DA.000001322",
    "description": "Изображения, на которых присутствуют контуры билбордов. Разметка описывает координаты области, внутри которой присутствует контур билборда заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 34,
    "title": "Распознавание деффектов бордюрных камней",
    "nsud_id": "00024F.DA.000001323",
    "description": "Изображения, на которых присутствуют контуры бордюрных камней с дефектами. Разметка описывает координаты области, внутри которой присутствует контур бордюрного камня с дефектами заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1196
  },
  {
    "id": 35,
    "title": "Распознавание граффити и рисунков вандального характера",
    "nsud_id": "00024F.DA.000001324",
    "description": "Изображения, на которых присутствуют контуры граффити и рисунков вандального характера. Разметка описывает координаты области, внутри которой присутствует контур граффити или рисунков вандального характера заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1038
  },
  {
    "id": 36,
    "title": "Заполнение мест сбора ТКО",
    "nsud_id": "00024F.DA.000001325",
    "description": "Изображения, на которых присутствуют контуры мест сбора ТКО производится детекция переполнения мест для сбора мусора. Разметка описывает координаты области, внутри которой присутствует место сбора ТКО заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 37,
    "title": "Загрязнение опор освещения и столбов рекламными стикерами",
    "nsud_id": "00024F.DA.000001326",
    "description": "Изображения, на которых присутствуют контуры загрязнение опор освещения и столбов рекламными стикерами - детекция рекламных стикеров на столбах для деятельности служб ЖКХ. Разметка описывает координаты области, внутри которой загрязнение заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 38,
    "title": "Детекция загрязнений на дорогах общего пользования, асфальтобетонном покрытии",
    "nsud_id": "00024F.DA.000001327",
    "description": "Изображения, на которых присутствуют контуры загрязнений на дорогах общего пользования, асфальтобетонном покрытии - детекция загрязнения асфальтобетонного покрытия листвой, отходами. Разметка описывает координаты области, внутри которой загрязнение заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 39,
    "title": "Детекция блоков кондиционеров на фасадах зданий",
    "nsud_id": "00024F.DA.000001328",
    "description": "Изображения, на которых присутствуют контуры блоков кондиционеров на фасадах зданий в части деятельности служб ЖКХ. Разметка описывает координаты области, внутри которой блок кондиционера заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 40,
    "title": "Распознавание облаков, теней облаков и иных объектов включая мониторинг природных и антропогенных объектов на основе космических снимков",
    "nsud_id": "00024F.DA.000001329",
    "description": "Изображения, на которых присутствуют лес,водные объекты, дороги грунтовые, дороги асфальтированные, дома жилые, дома нежилые, недавние потери леса. Классы указаны в соответствии с классификатором.",
    "value": 2520
  },
  {
    "id": 41,
    "title": "Распознавание прицепов транспортных средств на изображениях",
    "nsud_id": "00024F.DA.000001330",
    "description": "Изображения, на которых присутствуют контуры прицепов легковых авто, грузовых авто. Разметка описывает координаты области, внутри которой присутствуют контуры заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1015
  },
  {
    "id": 42,
    "title": "Распознавание общественного транспорта на изображениях (автобусы, трамваи, троллейбусы)",
    "nsud_id": "00024F.DA.000001331",
    "description": "Изображения, на которых присутствуют контуры общественного транспорта - автобусы и электробусы, трамваи, троллейбусы. Разметка описывает координаты области, внутри которой присутствуют контуры заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 43,
    "title": "Распознавание такси на изображениях",
    "nsud_id": "00024F.DA.000001332",
    "description": "Изображения, на которых присутствуют контуры такси и маршрутные такси. Разметка описывает координаты области, внутри которой присутствуют контуры заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1027
  },
  {
    "id": 44,
    "title": "Распознавание легковых автомобилей для целей определения выбросов загрязняющих веществ в атмосферный воздух от передвижных источников для проведения сводных расчетов загрязнения атмосферного воздуха",
    "nsud_id": "00024F.DA.000001333",
    "description": "Изображения, на которых присутствуют контуры легковые автомобили. Разметка описывает координаты области, внутри которой присутствуют контуры заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 45,
    "title": "Распознавание автофургонов и микроавтобусов до 3,5 тонн для целей определения выбросов загрязняющих веществ в атмосферный воздух от передвижных источников для проведения сводных расчетов загрязнения атмосферного воздуха",
    "nsud_id": "00024F.DA.000001334",
    "description": "Изображения, на которых присутствуют контуры автофургонов и микроавтобусов до 3,5 тонн - грузопассажирские автофургоны, с количеством мест для сидения не более девяти, включая место водителя и легкие автофургоны, пассажирские и грузовые транспортные средства малой Разметка описывает координаты области, внутри которой присутствуют контуры автофургонов и микроавтобусов до 3,5 тонн заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 46,
    "title": "Распознавание грузовых автомобилей от 3,5 до 12 тонн для целей определения выбросов загрязняющих веществ в атмосферный воздух от передвижных источников для проведения сводных расчетов загрязнения атмосферного воздуха",
    "nsud_id": "00024F.DA.000001335",
    "description": "Изображения, на которых присутствуют контуры грузовых автомобилей от 3,5 до 12 тонн двухосные и трехосные грузовые автомобили без прицепа. Разметка описывает координаты области, внутри которой присутствуют контуры грузовых автомобилей от 3,5 до 12 тонн заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 997
  },
  {
    "id": 47,
    "title": "Распознавание грузовых автомобилей свыше 12 тонн для целей определения выбросов загрязняющих веществ в атмосферный воздух от передвижных источников для проведения сводных расчетов загрязнения атмосферного воздуха",
    "nsud_id": "00024F.DA.000001336",
    "description": "Изображения, на которых присутствуют контуры грузовых автомобилей свыше 12 тонн четырехосные грузовые автомобили, а также грузовые автомобили с одним или несколькими прицепами; тягачи с полуприцепами и одним или несколькими прицепами; тягачи без прицепов и полуприцепов и специализированные транспортные средства, такие как самоходные дорожные катки, бульдозеры, автокраны, автоцистерны армейского образца. Разметка описывает координаты области, внутри которой присутствуют контуры грузовых автомобилей свыше 12 тонн заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1200
  },
  {
    "id": 48,
    "title": "Распознавание автобусов свыше 3,5 тонн для целей определения выбросов загрязняющих веществ в атмосферный воздух от передвижных источников для проведения сводных расчетов загрязнения атмосферного воздуха",
    "nsud_id": "00024F.DA.000001337",
    "description": "Изображения, на которых присутствуют контуры автобусов свыше 3,5 тонн. Разметка описывает координаты области, внутри которой присутствуют контуры автобусов свыше 3,5 тонн заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 915
  },
  {
    "id": 49,
    "title": "Детекция износа и повреждений поверхности дорожного покрытия",
    "nsud_id": "00024F.DA.000001338",
    "description": "Изображения, на которых присутствуют контуры износа и повреждений поверхности дорожного покрытия стертое полотно дорог, ямы, выбоены, трещины и просадки. Разметка описывает координаты области, внутри которой присутствуют контуры износа и повреждений поверхности",
    "value": 1198
  },
  {
    "id": 50,
    "title": "Детекция транспортных средств с БПЛА",
    "nsud_id": "00024F.DA.000001339",
    "description": "Изображения, на которых присутствуют контуры автотранспортных средства. Разметка описывает координаты области, внутри которой присутствуют транспортных средств заданного класса. Классы указаны в соответствии с классификатором.",
    "value": 1000
  }
]

export default data