import "../PaginationComponent/Pagination.scss"
import ReactPaginate from "react-paginate";
import {useEffect, useState} from "react";

function PaginationComponent({list,setCurrentItems}){
    const itemsPerPage = 10;
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset,setEndOffset] = useState(0)

    useEffect(()=>{
        setCurrentItems(list.slice(itemOffset, endOffset));
        setEndOffset(itemOffset + itemsPerPage)
    },[itemOffset,list,setCurrentItems,endOffset])

    const pageCount = Math.ceil(list.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % list.length;
        setItemOffset(newOffset);
    };



    return (
        <>
            <ReactPaginate

                breakLabel="..."
                nextLabel=""
                initialPage = { 0}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-prev"
                previousLinkClassName="page-link"
                nextClassName="page-next"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
        </>
    )
}

export default PaginationComponent;
