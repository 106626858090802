import React, {useEffect, useMemo, useRef, useState} from 'react';
import datasets from './datasets-list';
import './DatasetsPage.scss'

import SearchIcon from "../../assets/icons/d08.svg";
import LineIcon from "../../assets/icons/Line403.svg";
import CanselIcon from "../../assets/icons/b02.svg";

import Gerb from "../../assets/icons/gerb.svg";
import IconHide from "../../assets/icons/closed.svg";
import IconEye from "../../assets/icons/eye.svg";
import Pagination from "./Pagination";
import data from "./datasets-list";

const DatasetsPage = () => {
    const [showSearch, setShowSearch] = useState(false)
    const [search, setSearch] = useState("")
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [activeDataset,setActiveDataset] = useState("")
    const [viewList, setViewList] = useState(data)

    const [errorLocalAuth, setErrorLocalAuth] = useState(false)
    const [login,setLogin] = useState()
    const [password,setPassword] = useState()
    const [hidePass,setHidePass] = useState(true)
    const modalRef = useRef(null);

    function handleDatasetSearch(e){
        setSearch(e.target.value)
    }

    function handleCloseSearch(){
        setShowSearch(false)
        setSearch("")
    }


    const openModal = (e) => {
        e.preventDefault()

        setIsOpenModal(true)
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpenModal(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const local_auth = true


    const filteredList = useMemo(() => {
        return data.filter(({title})  => title.toLowerCase().includes(search.toLowerCase()))
    }, [search])

    return (
        <>
        <div className="content datasets-page">

            <div className="content_block">
                <div className="datasets_table">
                    <div className="datasets_title">
                        <p className="gt_h4">Проекты</p>
                    </div>


                    <div className="table_container">
                        <table className="table_main">
                            <thead>
                            <tr>
                                <th className="gt_f6 left_th">№</th>
                                {
                                    showSearch ?
                                        <th className="gt_f6  search_th">
                                            <div className="dataset_search">
                                                <input placeholder="Поиск" id="search" className="gt_pt" value={search}
                                                       onChange={(e) => handleDatasetSearch(e)}/>
                                                <div className="icon_block">
                                                    <img src={SearchIcon} alt="search"/>
                                                    <img src={LineIcon} alt="line"/>
                                                    <button type="button" className="gt_icon_btn"
                                                            onClick={handleCloseSearch}>
                                                        <img src={CanselIcon} alt="cansel"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </th> :
                                        <th className="gt_f6 ">
                                            Название
                                            <button type="button" onClick={() => setShowSearch(true)}
                                                    className="gt_icon_btn">
                                                <img src={SearchIcon} alt="search"/>
                                            </button>
                                        </th>
                                }

                                <th className="gt_f6">ID НСУД</th>
                                <th className="gt_f6">Объем, шт.</th>

                                <th className="gt_f6">Действие</th>
                            </tr>
                            </thead>
                            {viewList.map((dataset, i) => (
                                <button type="button"
                                        key={dataset.id}
                                        className={`table_item ${activeDataset === dataset.id ? "active" : ""}`}

                                        onClick={() => setActiveDataset(dataset.id)}
                                >
                                    <tr>
                                        <td className="gt_pt left_td" >{dataset.id}</td>
                                        <td className="gt_pt" style={{textAlign: 'left'}}>{dataset.title}</td>
                                        <td>{dataset.nsud_id}</td>
                                        <td>{dataset.value}</td>
                                        <td>
                                            <a onClick={openModal} className='action' href="#">Просмотр</a>
                                        </td>
                                    </tr>
                                </button>
                            ))}

                        </table>
                    </div>

                    <Pagination list={filteredList} setCurrentItems={setViewList} />
                </div>
                <div className="block-container">
                    <div className="datasets_title">
                        <p className="gt_h4">Описание</p>
                    </div>

                    <div className="description">
                        {datasets.find(d => d.id === activeDataset)?.description || datasets[0].description}
                    </div>
                </div>
            </div>




        </div>


            {isOpenModal &&
                <div className="modal login-modal">
                    <div ref={modalRef} className={`login_cart ${local_auth ? "login_cart_local" : ""}`}>
                        <div className="login_content">
                            <div className="login_title">
                                <img className="login_logo" src={Gerb} alt="Логотип"/>
                                <div className="gt_h5 gt_gdg">Государственная платформа искусственного интеллекта</div>
                            </div>


                            <div className="login_local">
                                <input
                                    className={`login_local_input gt_pt ${errorLocalAuth ? "login_local_error" : ""}`}
                                    placeholder="Логин"
                                    type="text"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                    onClick={() => setErrorLocalAuth(false)}
                                />
                                <div className="pass_block">
                                    <input
                                        className={`login_local_input gt_pt ${errorLocalAuth ? "login_local_error" : ""}`}
                                        placeholder="Пароль"
                                        type={hidePass ? "password" : "text"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onClick={() => setErrorLocalAuth(false)}

                                    />
                                    {errorLocalAuth && <p className="gt_ps gt_gr">Неправильный логин или пароль</p>}
                                    <button className="pass_icon" type="button" onClick={() => setHidePass(!hidePass)}>
                                        <img alt="icon" src={hidePass ? IconHide : IconEye}/>
                                    </button>
                                </div>



                                <button type="button" className="gt_btn_primary gt_pt login_btn"
                                        onClick={() => (login || password) && setErrorLocalAuth(true) }>
                                    Войти
                                </button>





                            </div>

                        </div>

                    </div>
                </div>
            }
        </>
    )
};

export default DatasetsPage;