import './Header.scss';
import Account from "../../assets/icons/account.svg";
import Logo from "../../assets/icons/logo_log-in.svg";
import InfoIcon from "../../assets/icons/d07_1.svg";
import {useDispatch, useSelector} from "react-redux";
import {hideNotificationAction, showNotificationAction} from "../../store/reducers/appReducer";
import {logoutUserAction} from "../../store/actions/userAction";

function Header() {

    const dispatch = useDispatch()
    const {userInfo} = useSelector(store=>store.user)
    const {showNotification} = useSelector(store=>store.app)

    async function handleExit(){
        await dispatch(logoutUserAction())

    }

    function handleNotification(){
        if (showNotification){
            dispatch(hideNotificationAction())
        }else {
            dispatch(showNotificationAction())
        }
    }
    return (
        <nav className="header" data-testid="test-header" >
            <div className="header_brand">
                <div
                    className="header_logo-link"
                >
                    <img className="header_logo" src={Logo} alt="Логотип" />
                </div>
            </div>
            {userInfo?.name && <div className="header_left_block">
                <div className="header_user-info">
                    <button type="button" onClick={handleExit} className="header_exit_btn">
                        <p className="gt_pt">Выйти</p>
                    </button>
                </div>
                <div className="header_user-info">
                    <button type="button" className="header_info_btn" onClick={handleNotification}>
                    <img src={InfoIcon} alt="Логотип"/>
                    </button>
                </div>
                <div className="header_user-info hr_block">

                </div>
                <div className="header_user-info">
                    <img className="header_account-icon" src={Account} alt="Логотип"/>
                    <p className="gt_pt">
                        {userInfo ? userInfo.name : ""}
                    </p>

                </div>
            </div>}
        </nav>
    );
}

export default Header;
